import React from "react"
import Layout from "../components/layout/layout.js"
import Seo from "../components/seo"
import {graphql} from "gatsby";

const NotFoundPage = ({data}) => (
	<Layout>
		<Seo title="404: Not found" />
		<h1>NOT FOUND</h1>
		<p>{console.info(data)}</p>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>

		<img src={data.file.publicURL} alt="404"/>
	</Layout>
)

export default NotFoundPage

export const query = graphql`
	query {
		file(relativePath: { eq: "undraw_page_not_found_su7k.svg" }) {
			publicURL
		}
	}
`